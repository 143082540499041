import React from "react"
import styles from "./productStyles.module.scss"

const Product = ({
  children,
  header,
  description,
  imgPosition = "left",
  style,
  className,
}) => {
  return (
    <div
      className={[
        styles.wrapper,
        imgPosition === "right" ? styles.right : styles.left,
      ].join(" ")}
      style={{ ...style }}
    >
      {imgPosition === "left" ? (
        <div style={{ minHeight: "300px", minWidth: "300px" }}>{children}</div>
      ) : null}
      <div
        className={[
          styles.textWrapper,
          imgPosition === "left" ? styles.leftText : styles.rightText,
        ].join(" ")}
      >
        <h1 className={[styles.header, "responsiveAlign"].join(" ")}>
          {header}
        </h1>
        <p className={styles.description}>{description}</p>
      </div>
      {imgPosition === "right" ? (
        <div style={{ minHeight: "300px", minWidth: "300px" }}>{children}</div>
      ) : null}
    </div>
  )
}

export default Product
