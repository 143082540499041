import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import ColorDots from "../components/ColorDots/ColorDots"
import Button from "../components/Button/Button"
import EmailSignup from "../components/EmailSignup/EmailSignup"
import Footer from "../components/Footer/Footer"

import mainStyles from "./indexStyles.module.scss"
import PateDeFruit from "../components/imageComponents/pateDeFruit"
import Product from "../components/Product/Product"
import Buttermints from "../components/imageComponents/buttermints"
import EnglishToffee from "../components/imageComponents/englishToffee"
import BeefJerky from "../components/imageComponents/beefJerky"
import Stroopwafel from "../components/imageComponents/stroopwafel"
import Container from "../components/Container/Container"
import Mints from "../components/imageComponents/mints"
import FreshBud from "../components/imageComponents/freshBud"

const Products = () => {
  return (
    <Layout>
      <Container>
        <div
          className={["centered", "parallaxMiddle"].join(" ")}
          style={{ marginTop: "100px" }}
        >
          <h1 style={{ maxWidth: "800px", margin: "50px auto" }}>
            Personalized Cannabis Therapy with Trusted Results
          </h1>
          <p style={{ marginBottom: "50px" }}>
            You can trust KindBio products for quality, traceability, and
            efficacy.
          </p>
          <ColorDots></ColorDots>
        </div>
        <div
          style={{
            alignItems: "center",
            position: "relative",
            margin: "100px 0",
          }}
          className={[mainStyles.responsiveFlexRow, "parallaxMiddle"].join(" ")}
        >
          <div
            className={[mainStyles.freshBudWrapper, "parallaxChild"].join(" ")}
          >
            <FreshBud></FreshBud>
          </div>
          <div
            style={{
              flex: "50%",
              opacity: 0,
              minWidth: "50%",
              minHeight: "calc(300px + 10vw)",
            }}
          >
            <FreshBud></FreshBud>
          </div>
          <div style={{ flex: "50%" }}>
            <h1 className={mainStyles.responsiveAlign}>Fine Cannabis</h1>
            <p style={{ maxWidth: "600px", marginTop: "20px" }}>
              Cultivated in Missouri featuring tested and certified strains,
              KindBio Fine Cannabis is cultivated with the patient in mind.
              Experience therapeutic benefits available in ultra-premium flower,
              pre-rolls, vapes and tinctures.
            </p>
          </div>
        </div>
        <div
          style={{
            alignItems: "center",
            position: "relative",
          }}
          className={[
            mainStyles.responsiveFlexRowReverse,
            "parallaxMiddle",
          ].join(" ")}
        >
          <div
            className={[
              mainStyles.bespokeImageWrapper,
              "parallaxChild",
              "moveRight",
            ].join(" ")}
          >
            <Mints></Mints>
          </div>
          <div style={{ flex: "50%" }}>
            <div style={{ maxWidth: "600px", marginLeft: "auto" }}>
              <h1 className={"responsiveAlign"}>Bespoke Edibles</h1>
              <p style={{ marginTop: "20px" }}>
                Carefully crafted by regional chefs using local ingredients, the
                KindBio edible line is a culinary delight featuring Paté de
                Fruit, Mint Meltaways, keto caramels, English toffee,
                keto-friendly beef jerky, and even a Dutch classic&mdash;the
                Stroopwafel. Focusing on great taste and a consistent medicinal
                effect, each edible is infused with precise doses from the
                finest cannabis flower.
              </p>
            </div>
          </div>
          <div
            style={{
              flex: "50%",
              opacity: 0,
              minWidth: "50%",
              minHeight: "calc(200px + 10vw)",
            }}
          >
            <Mints></Mints>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "150px",
          }}
        >
          <Product
            header="Pate De Fruit"
            description="Antioxidant rich fruit purees make this melt in your mouth sweet and sour edible a treat to consume!"
            style={{ margin: "40px" }}
          >
            <PateDeFruit />
          </Product>
          <Product
            header="Beef Jerky"
            description="Made in small batches from Missouri Rain Crow Ranch grass fed beef cuts. Absolutely the best savory edible you ever experience. Keto friendly too!"
            style={{ margin: "40px" }}
            imgPosition="right"
          >
            <BeefJerky />
          </Product>

          <Product
            header="English Toffee"
            description="Crunchy, buttery with Missouri grown nuts. We drench the entire piece in creamy fair-trade Askinosie white chocolate and finish it off with a coating of crunchy nuts. Amazing!"
            style={{ margin: "40px" }}
          >
            <EnglishToffee />
          </Product>
          <Product
            header="Mint Meltaways"
            description="These microdosed little lovelies will melt on your tongue and deliver the medicine quickly and at 2.5 mgs or less per piece you can choose your perfect experience."
            style={{ margin: "40px" }}
            imgPosition="right"
          >
            <Buttermints />
          </Product>

          <Product
            header="The Dutchie"
            description="This stroopwafel, an Amsterdam street food, is widely popular in the morning for the best wake and bake experience. Just set The Dutchie on your coffee or tea mug and let the chocolate pecan “Motella” melt a little from the heat of your cup and you will have a breakfast epiphany!"
            style={{ margin: "40px" }}
          >
            <Stroopwafel />
          </Product>
        </div>

        <div className="centered">
          <h1 style={{ maxWidth: "800px", margin: "50px auto" }}>
            Partner with Missouri's Most Trusted Producer of Fine Cannabis
          </h1>
          <p style={{ margin: "50px auto" }}>
            Offer patients the therapeutic treatment with trusted results.
          </p>
          <Button
            onClick={() => {
              navigate("/contact")
            }}
          >
            CONTACT US
          </Button>
        </div>
      </Container>
      <EmailSignup></EmailSignup>
      <Footer></Footer>
    </Layout>
  )
}

export default Products
